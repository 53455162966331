@import "../../scss/mixin";

.top-bar {
  width: 100%;
  background-color: var(--bs-white);
  padding: size(25) size(20);
  @include radius(size(10));
  margin-bottom: size(25);
  @include box-shadow(0px 4px 8px var(--bs-breadcrum-shadow));
  flex-wrap: wrap;
  @include for-medium-desktop {
    padding: size(15) size(20);
    @include radius(size(8));
    margin-bottom: size(20);
  }
  @include for-mobile-device {
    padding: size(10) size(15);
    @include radius(size(5));
    margin-bottom: size(15);
  }

  h1 {
    font-size: size(24);
    font-weight: 500;
    font-family: var(--bs-body-font-family);
    text-transform: capitalize;
    @include for-medium-desktop {
      font-size: size(18);
    }
    @include for-mobile-device {
      font-size: size(14);
    }
  }
}

.MuiBreadcrumbs-root {
  .MuiBreadcrumbs-ol {
    .MuiBreadcrumbs-li {
      .MuiTypography-root {
        font-size: size(14);
        display: block;
        text-transform: capitalize;
        font-family: var(--bs-body-font-family);
        color: var(--bs-secondary-black);
        @include for-mobile-device {
          font-size: size(12);
        }
        @include for-small-mobile-device {
          font-size: size(11);
        }
      }

      a {
        color: var(--bs-nav-color);
        // color: $black;
        cursor: pointer;
        position: relative;

        &::before {
          @include beforeBl;
          width: 100%;
          height: 1px;
          background: var(--bs-btn-gradient);
          @include transform(scaleX(0) translateZ(0));
          @include transform-origin(100% 100%);
          @include transition(transform 0.5s cubic-bezier(0.19, 1, 0.22, 1));
        }

        &:hover {
          &::before {
            @include transform(scaleX(1) translateZ(0));
            @include transform-origin(0 0);
          }
        }
      }
    }

    .MuiBreadcrumbs-separator {
      font-size: size(14);
    }
  }
}
